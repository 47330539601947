<template>
  <div>
    <UIExit :label="exitFrom" />
    <TitleWrapper
      :filter-option="false"
      title="FEE_DETAILS"
      tooltip-title="FEE_DETAILS"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <!-- Personal Information -->
    <div>
      <div class="flex flex-col p-5 border border-primary-gray-200 content-col bg-white rounded-lg">
        <div class="flex xsm">
          <div class="flex flex-1 font-roboto font-base mb-5 sm:gap-9">
            <div class="pr-1 w-29">Student:</div>
            <div class="text-menu capitalize flex-1">
              {{
                feeDetails &&
                feeDetails.student &&
                `${feeDetails.student.first_name} ${feeDetails.student.last_name}`
              }}
            </div>
          </div>
          <div class="flex flex-1 font-roboto font-base mb-5 sm:gap-9">
            <div class="pr-1 w-29">Discount:</div>
            <div class="text-menu">
              {{ feeDetails.discount_amount }}
            </div>
          </div>
        </div>
        <div class="flex xsm">
          <div class="flex flex-1 font-roboto font-base mb-5 sm:gap-9">
            <div class="pr-1 w-29">Fee Type:</div>
            <div class="text-menu">
              {{
                (feeDetails.fee_type && feeDetails.fee_type.title) ||
                GENERAL_CONSTANTS.NOT_APPLICABLE
              }}
            </div>
          </div>
          <div class="flex flex-1 font-roboto font-base mb-5 sm:gap-9">
            <div class="pr-1 w-29">Fine:</div>
            <div class="text-menu">
              {{ feeDetails.fine_amount }}
            </div>
          </div>
        </div>
        <div class="flex xsm">
          <div class="flex flex-1 font-roboto font-base mb-5 sm:gap-9">
            <div class="pr-1 w-29">Total Fee:</div>
            <div class="text-menu">
              {{ feeDetails.fee_amount }}
            </div>
          </div>
          <div class="flex flex-1 font-roboto font-base mb-5 sm:gap-9">
            <div class="pr-1 w-29">Due Date:</div>
            <div class="text-menu flex-1">
              {{
                (feeDetails.due_date && formatDate(feeDetails.due_date)) ||
                GENERAL_CONSTANTS.NOT_APPLICABLE
              }}
            </div>
          </div>
        </div>
        <div class="flex xsm">
          <div class="flex flex-1 font-roboto font-base mb-5 sm:gap-9">
            <div class="pr-1 w-29">{{ isGuardianOrStudent ? 'Payable' : 'Receivable' }} Fee:</div>
            <div class="text-menu">
              {{ feeDetails.payable_amount }}
            </div>
          </div>
          <div class="flex flex-1 flex-wrap font-roboto font-base mb-5 sm:gap-9">
            <div class="pr-1 w-29">Fee Title:</div>
            <div class="text-menu capitalize flex-1">
              {{
                (feeDetails.section_fee && feeDetails.section_fee.title) ||
                feeDetails.title ||
                GENERAL_CONSTANTS.NOT_APPLICABLE
              }}
            </div>
          </div>
        </div>
        <div class="w-full sm:w-2/4">
          <div class="flex font-roboto font-base sm:gap-9">
            <div class="pr-1 w-29">{{ isGuardianOrStudent ? 'Paid' : 'Received' }} Fee:</div>
            <div class="text-menu">
              {{ feeDetails.paid_amount }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="isLoading" class="my-28">
        <Loader :content="true" />
      </div>
      <div v-else-if="!isLoading && isEmpty(studentReceivedInstallments)">
        <NoRecordFound />
      </div>
      <div v-else class="w-full rounded-lg mt-1">
        <!-- Fee Installments -->
        <div class="p-2 font-medium font-roboto text-xl text-label-text mt-3">Installments</div>
        <TableWrapper>
          <THead>
            <TRHead>
              <TH v-for="(head, index) in tHead" :key="index">
                {{ head }}
              </TH>
            </TRHead>
          </THead>
          <TBody>
            <TRBody v-for="(fee, index) in studentReceivedInstallments" :key="index">
              <TD>
                {{ fee.paid_amount }}
              </TD>
              <TD>
                {{ fee.payable_amount }}
              </TD>
              <TD>
                {{
                  (fee.received_by &&
                    `${fee.received_by.first_name} ${fee.received_by.last_name}`) ||
                  GENERAL_CONSTANTS.NOT_APPLICABLE
                }}
              </TD>
              <TD>
                {{ fee.payment_method || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
              <TD>
                {{ formatDate(fee.paid_date) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
              <TD>
                {{ fee.payment_comment || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
            </TRBody>
          </TBody>
        </TableWrapper>
      </div>
      <Pagination
        v-if="studentReceivedInstallmentsCounts > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT"
        v-show="!isLoading"
        :record-limit="GENERAL_CONSTANTS.MODAL_RECORD_LIMIT"
        :max-range="
          paginationCounts(studentReceivedInstallmentsCounts, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)
        "
        @filterRecord="filterRecord"
      />
    </div>
  </div>
</template>

<script>
import UIExit from '@/src/components/UiElements/UIExit.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions } from 'vuex'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import Pagination from '@components/BaseComponent/Pagination.vue'
import GeneralMixins from '@src/mixins/general-mixins.js'
import Loader from '@components/BaseComponent/Loader.vue'
import { formatDate } from '@utils/moment.util'
import isEmpty from 'lodash/isEmpty'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import { mapState } from 'vuex'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import { buildWhereQuery } from '@/src/utils/filters'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    UIExit,
    TitleWrapper,
    Pagination,
    Loader,
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralMixins, scrollMixin],
  data() {
    return {
      GENERAL_CONSTANTS,
      formatDate,
      feeDetails: {},
      tHead: [
        'Paid',
        'Remaining',
        'Received By',
        'Payment Method',
        'Receiving Date',
        'Payment Comment',
      ],
      isLoading: false,
      isEmpty,
      redirectTo: localStorage.getItem('redirectTo'),
    }
  },
  computed: {
    ...mapState({
      activeRole: (state) => state.layout.activeRole,
      studentReceivedInstallments: (state) => state.fee.studentReceivedInstallments?.records || [],
      studentReceivedInstallmentsCounts: (state) =>
        state.fee.studentReceivedInstallments?.meta?.total_records,
    }),
    isGuardianOrStudent() {
      return [TENANT_ROLES.SECTION_STUDENT, TENANT_ROLES.GUARDIAN].includes(this.activeRole)
    },
    exitFrom() {
      if (this.redirectTo === 'payable') {
        return !this.isGuardianOrStudent ? 'Exit Receivable' : 'Exit Payable'
      } else return !this.isGuardianOrStudent ? 'Exit Received' : 'Exit Paid'
    },
    feeId() {
      return this.$route?.params?.id
    },
  },
  page: {
    title: 'Salary Detail',
  },
  mounted() {
    this.getFeeDetail()
    this.filterRecord()
  },
  methods: {
    getFeeDetail() {
      this.isLoading = true
      this.getStudentFeeDetails(this.feeId).then((res) => {
        this.feeDetails = res?.data || {}
        this.isLoading = false
      })
    },
    filterRecord(range) {
      this.isLoading = true
      let paginationRange = paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)
      let queryPayload = {
        $where: { ...buildWhereQuery(FILTER_KEYS.EQUAL, 'student_fee_id', this.feeId) },
      }

      let payload = {
        ...paginationRange,
        ...queryPayload,
      }
      this.getStudentFeeReceivings(payload).then(() => {
        this.isLoading = false
      })
    },
    ...mapActions('fee', ['getStudentFeeDetails', 'getStudentFeeReceivings']),
  },
}
</script>

<style>
@media screen and (max-width: 424px) {
  .content-col {
    display: flex;
    flex-direction: column;
  }
  .xsm {
    display: flex;
    flex-direction: column;
  }
}
</style>
